.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.code {
  background-color: #1f1f1f;
  width: 100%;
  padding: 40px 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  min-height: 30vh;
  overflow: hidden;
  overflow-y: hidden;
}

.warning {
  padding-bottom: 10px;
  align-self: center;
}

.icon_warning {
  padding-right: 5px;
}

.icon_logout {
  color: #fff;
  font-size: 1.5em;
}

.button_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.login {
  background-color: #3e70fa;
  color: #fff;
  padding: 5px 30px;
  border-radius: 6px;
  font-size: 1.5em;
  font-weight: 600;
  cursor: pointer;
  align-self: center;
}

.login:disabled {
  background-color: #3e70fa;
  color: #fff;
  opacity: 0.5;
  cursor: not-allowed;
}

.login:hover {
  background-color: #2849a4;
  color: #fff;
}

.clientId {
  align-self: center;
  margin-bottom: 8px;
}

pre {
  background-color: #1f1f1f !important;
  font-size: 0.8rem;
  width: 100% !important;
  word-break: break-all !important;
  overflow: hidden !important;
  word-wrap: break-word !important;
}
