.contentHeader {
  display: block;
  font-size: 1.4em;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 4px;
  color: #3e70fa;
}

.contentHeader:hover {
  background-color: #3e70fa;
  color: white;
}

.container {
  padding: 20px 0px;
  margin: auto;
  height: 100%;
}

.gutter {
  margin-bottom: 18px;
}

.link {
  margin-right: 10px;
}
